import React from 'react'
import PropTypes from 'prop-types'
import SearchResult from '../components/SearchResult'
import Typography from '@material-ui/core/Typography'
import SearchBoxContainer from 'search/containers/SearchBoxContainer'
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({

  searchInput: {
    color: theme.palette.primary.main,
  },

  searchIcon: {
    color: theme.palette.primary.main,
    verticalAlign: 'middle'
  },

  wrapper: {
    color: theme.palette.common.white,
    background: fade(theme.palette.primary.main, 0.15),
    '&:hover': {
      background: fade(theme.palette.primary.main, 0.15),
    },
  }

});

class SearchResults extends React.Component {

  renderSearchResults() {
    if (this.props.results != null) {
      const resultsList = this.props.results.map((show) => {
        return (
          <Grid item xs={12}>
            <SearchResult key={show.id} show={show} />
          </Grid>
        );
      });
      return (
        <>
          <Grid item xs={12} id="search-results">
            <Typography variant="h4">search results for &quot;{this.props.query}&quot;</Typography>
          </Grid>
          {resultsList}
        </>
      );
    } else {
      return null;
    }
  }


  render() {
    return (
      <Grid container spacing={24}>
        <Hidden smUp>
          <Grid item xs={12}>
            <SearchBoxContainer classes={this.props.classes} />
          </Grid>
        </Hidden>
        {this.renderSearchResults()}
      </Grid>
    );
  }
}

SearchResults.propTypes = {
  results: PropTypes.array,
  query: PropTypes.string.isRequired
}

export default withStyles(styles)(SearchResults);