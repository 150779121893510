import { connect } from 'react-redux'
import WatchEpisodeButton from '../components/WatchEpisodeButton'

import { markEpisodeWatched as markEpisodeWatchedAction } from 'state/user/watched/actions'
import { unmarkEpisodeWatched as unmarkEpisodeWatchedAction } from 'state/user/watched/actions'

import { getIsSignedIn } from 'state/user/auth/selectors'
import { hasWatchedEpisode } from 'state/user/watched/selectors'

const mapStateToProps = (state, ownProps) => {
  const { episodeId } = ownProps;
  
  const hasWatched = hasWatchedEpisode(state, episodeId);

  return {
    ...ownProps,
    isSignedIn: getIsSignedIn(state),
    hasWatched
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onMarkEpisodeWatched: (episodeId) => dispatch(markEpisodeWatchedAction(episodeId)),
    onUnmarkEpisodeWatched: (episodeId) => dispatch(unmarkEpisodeWatchedAction(episodeId)),
  }
}

const WatchEpisodeButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WatchEpisodeButton)

export default WatchEpisodeButtonContainer;
