import moment from 'moment'

function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action)
    } else {
      return state
    }
  }
}


function getCurrentTimestamp() {
  return Math.round(+new Date()/1000);
}

function prettyPrintDate(/*2001-09-20*/ ymdString, withTolerance) {
  const momentDate = moment(ymdString);

  return momentDate.calendar(
      withTolerance ? 
          moment().subtract(3, 'hours').startOf('day') :
          null, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd MMMM D',
    lastDay: 'dddd MMMM D',
    lastWeek: 'dddd MMMM D',
    sameElse: 'dddd MMMM D'
  });
}

function prettyPrintTime(/*21:00*/ timeString) {
  const momentTime = moment(timeString, 'HH:mm');
  return momentTime.format('h:mma');
}

function hasEpisodeAired(ymdString, timeString) {

  const episodeMoment = moment(ymdString + ' ' + timeString, 'YYYY-MM-DD HH:mm');

  return episodeMoment.isBefore();
}

export { createReducer, getCurrentTimestamp, prettyPrintDate, prettyPrintTime, hasEpisodeAired };