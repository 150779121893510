import { connect } from 'react-redux'
import Season from '../components/Season'

import { hasEpisodeAired } from 'utils'
import { isShowSeasonOnWatchList } from 'state/user/watchlist/selectors'
import { hasWatchedEpisode } from 'state/user/watched/selectors'

const mapStateToProps = (state, ownProps) => {
  const { episodes, showId, showName } = ownProps;

  const seasonNumber = episodes[0].season;

  const isOnWatchList = isShowSeasonOnWatchList(state, showId, seasonNumber);
  const airedEpisodeCount = episodes.filter(episode => hasEpisodeAired(episode.airdate, episode.airtime)).length;
  const watchedEpisodeCount = episodes.filter(episode => hasWatchedEpisode(state, episode.id)).length;

  return {
    episodes,
    showId,
    airedEpisodeCount,
    watchedEpisodeCount,
    isOnWatchList,
    showName
  }
}


const SeasonContainer = connect(
  mapStateToProps
)(Season)

export default SeasonContainer