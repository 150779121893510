import { 
    WATCHLIST_ADD_SEASON_REQUEST,
    WATCHLIST_REMOVE_SEASON,
    MARK_EPISODE_WATCHED,
    UNMARK_EPISODE_WATCHED,
    SUBSCRIPTION_ADD_SHOW,
    SUBSCRIPTION_REMOVE_SHOW,
    SUBSCRIPTION_ADD_NEW_WATCHLIST_SEASON,
} from 'state/action-types';

import { getIsSignedIn, getIdToken } from 'state/user/auth/selectors'
import { getWatchList } from 'state/user/watchlist/selectors'
import { getSubscriptionList } from 'state/user/subscription/selectors'
import { getWatchedData } from 'state/user/watched/selectors'
import { getLastRetrievedVersion } from 'state/user/persistence/selectors'
import { requestPersistUserData } from 'state/user/persistence/actions'

let saveActionTimeoutId = null;

const SAVE_TIMEOUT = 5 * 1000;
//const RESYNC_TIMEOUT = 30 * 1000;
const PERSISTENCE_ACTIONS = [
    WATCHLIST_ADD_SEASON_REQUEST,
    WATCHLIST_REMOVE_SEASON,
    MARK_EPISODE_WATCHED,
    UNMARK_EPISODE_WATCHED,
    SUBSCRIPTION_ADD_SHOW,
    SUBSCRIPTION_REMOVE_SHOW,
    SUBSCRIPTION_ADD_NEW_WATCHLIST_SEASON,

];

const persistenceTimerMiddleware = store => next => action => {
  const fireSaveAction = () => {
    const state = store.getState();
    if (getIsSignedIn(state)) {
      store.dispatch(requestPersistUserData({
        idToken: getIdToken(state),
        data: JSON.stringify({
          subscription: getSubscriptionList(state),
          watchlist: getWatchList(state),
          watched: getWatchedData(state),
        }),
        lastRetrievedVersion: getLastRetrievedVersion(state)
      }));
    }
  }

  if (PERSISTENCE_ACTIONS.indexOf(action.type) > -1) {
    if (saveActionTimeoutId != null) {
      window.clearTimeout(saveActionTimeoutId);
      saveActionTimeoutId = null;
    }

    saveActionTimeoutId = window.setTimeout(fireSaveAction, SAVE_TIMEOUT)
  }

  return next(action);
}

export default persistenceTimerMiddleware;