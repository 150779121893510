import React from 'react'
import PropTypes from 'prop-types'
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  
  
});

class WatchEpisodeButton extends React.Component {

  handleChange = episodeId => event => {
    if (event.target.checked) {
      this.props.onMarkEpisodeWatched(episodeId)
    } else {
      this.props.onUnmarkEpisodeWatched(episodeId)
    }
  }

  render() {
    const { episodeId, hasWatched, isSignedIn } = this.props
    
    if ( !isSignedIn ) return null;

    return (
      <div>
        <Switch
          checked={hasWatched}
          onChange={this.handleChange(episodeId)}
          value={episodeId.toString()} />
      </div>
    )
  }
}


WatchEpisodeButton.propTypes = {
  onMarkEpisodeWatched: PropTypes.func,
  onUnmarkEpisodeWatched: PropTypes.func,
  hasWatched: PropTypes.bool.isRequired,
  episodeId: PropTypes.number.isRequired,
  isSignedIn: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(WatchEpisodeButton);