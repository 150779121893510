import { combineReducers } from 'redux'

import episodes from './episodes/reducer'
import shows from './shows/reducer'
import sync from './sync/reducer'

export const reducer = combineReducers({
  episodes,
  shows,
  sync,
});