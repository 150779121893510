import { connect } from 'react-redux'
import Watchlist from '../components/Watchlist'
import { getWatchList } from 'state/user/watchlist/selectors'
import { getShowInfo, getSeasonEpisodes } from 'state/database/selectors';
import { hasWatchedEpisode } from 'state/user/watched/selectors'

const mapStateToProps = (state, ownProps) => {
  const watchlist = getWatchList(state);
  const { startDate, endDate, showWatched } = ownProps;

  const aggregatedWatchlistData = [];

  let isMissingInfo = false;

  Object.keys(watchlist).forEach(showId => {
    const showInfo = getShowInfo(state, showId);
    
    if (showInfo) {
      let subscribedSeasons = watchlist[showId];

      subscribedSeasons.forEach( seasonNumber => {
        const episodes = getSeasonEpisodes(state, showId, seasonNumber)
          .filter(episode => episode.airdate >= startDate && episode.airdate <= endDate)
          .filter(episode => showWatched || !hasWatchedEpisode(state, episode.id));

        if (episodes && episodes.length > 0) {
          aggregatedWatchlistData.push({
            showInfo,
            seasonNumber,
            episodes,
          });
        } else {
          isMissingInfo = true;
        }
      });
    } else {
      isMissingInfo = true;
    }
 
  });
  return { aggregatedWatchlistData, isMissingInfo };
}

const WatchlistContainer = connect(
  mapStateToProps,
)(Watchlist)

export default WatchlistContainer