import React from 'react'
import PropTypes from 'prop-types'
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles'


const styles = {
  root: {
    flexGrow: 1,
  },
};

class SyncProgress extends React.Component {
  render() {
    const { classes, progress, isSyncing } = this.props;
    if (isSyncing) {
      return (
        <div className={classes.root}>
          <LinearProgress color="secondary" variant="determinate" value={progress} />
        </div>
      );
    } else {
      return null;
    }
  }
}

SyncProgress.propTypes = {
  progress: PropTypes.number.isRequired,
  isSyncing: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SyncProgress)