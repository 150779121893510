import { connect } from 'react-redux'
import Auth from '../components/Auth'
import { signIn as signInAction, signOut as signOutAction } from 'state/user/auth/actions'
import { getIsSignedIn, getUserName, getIdToken } from 'state/user/auth/selectors'
import { requestGetUserData } from 'state/user/persistence/actions'
import { requestSyncDatabase } from 'state/database/sync/actions'

const mapStateToProps = (state) => {
  return {
    isSignedIn: getIsSignedIn(state),
    name: getUserName(state),
    idToken: getIdToken(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (user) => {
      dispatch(signInAction(user))
      dispatch(requestGetUserData(user))
    },
    signOut: () => {
      dispatch(signOutAction())
    },
    sync: () => {
      dispatch(requestSyncDatabase())
    },
    syncUser: (user) => {
      dispatch(requestGetUserData(user))
    },

  }
}

const AuthContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Auth)

export default AuthContainer