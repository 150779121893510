import TvMaze from 'api/TvMaze'
import {
    SHOW_INFO_REQUEST,
    SHOW_INFO_REQUEST_SUCCESS,
    SHOW_INFO_REQUEST_ERROR
} from 'state/action-types';

export function requestShowInfo(showId) {
  return (dispatch) => {
    
    dispatch({
      type: SHOW_INFO_REQUEST,
      showId
    });

    return Promise.all([
          TvMaze.getShow(showId),
          TvMaze.getEpisodes(showId)
    ]).then(values => {
      const showInfo = values[0];
      const episodeData = values[1];

      let episodes = [];
      episodeData.forEach( (episode) => {
        if(!episodes[episode.season]) episodes[episode.season] = [];
        episodes[episode.season].push(episode);
      })
      
      dispatch({
        type: SHOW_INFO_REQUEST_SUCCESS,
        showId,
        showInfo,
        episodes
      });
    }).catch((error) => {
      console.log(error);
      dispatch({
        type: SHOW_INFO_REQUEST_ERROR,
        showId
      });
    });
    
  };
}
