import { connect } from 'react-redux'
import SearchResults from '../components/SearchResults'
import { getSearchQuery, getSearchResults } from 'state/ui/show-search/selectors'

const mapStateToProps = (state) => {
  return {
    query: getSearchQuery(state),
    results: getSearchResults(state),
  }
}

const SearchResultsContainer = connect(
  mapStateToProps,
)(SearchResults)

export default SearchResultsContainer
