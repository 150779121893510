import { connect } from 'react-redux'
import SubscribeShowButton from '../components/SubscribeShowButton'

import { getIsSignedIn } from 'state/user/auth/selectors'

import { subscribeToShow as subscribeToShowAction } from 'state/user/subscription/actions'
import { unsubscribeToShow as unsubscribeToShowAction } from 'state/user/subscription/actions'

import { isShowSubscribed } from 'state/user/subscription/selectors'

import { getCurrentTimestamp } from 'utils'

const mapStateToProps = (state, ownProps) => {
  const { showId } = ownProps;
  
  const isSubscribed = isShowSubscribed(state, showId);

  return {
    ...ownProps,
    isSignedIn: getIsSignedIn(state),
    isSubscribed,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubscribeShow: (showId) => dispatch(subscribeToShowAction(showId, getCurrentTimestamp())),
    onUnsubscribeShow: (showId) => dispatch(unsubscribeToShowAction(showId)),
  }
}

const SubscribeShowButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscribeShowButton)

export default SubscribeShowButtonContainer
