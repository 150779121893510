import { combineReducers } from 'redux'
import { createReducer } from 'utils'
import {
    BACKLOG_DURATION_CHANGE,
    BACKLOG_SHOW_WATCHED_CHANGE,
} from 'state/action-types';

export const duration = createReducer('P14D', {
  [BACKLOG_DURATION_CHANGE]: (state, { duration }) => duration
});

export const showWatched = createReducer(false, {
  [BACKLOG_SHOW_WATCHED_CHANGE]: (state, { showWatched }) => showWatched
})

export default combineReducers({
  duration,
  showWatched
});