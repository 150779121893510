import TvMaze from 'api/TvMaze'
import {
    SHOW_SEARCH_REQUEST,
    SHOW_SEARCH_REQUEST_SUCCESS,
    SHOW_SEARCH_REQUEST_ERROR
} from 'state/action-types';

/**
 * Searches for shows matching query from TvMaze
 *
 * @param {String} query the search string
 */
export function requestSearchResults(query) {
  return (dispatch) => {
    dispatch({
      type: SHOW_SEARCH_REQUEST,
      query
    });

    return TvMaze.searchShow(query).then((results) => {
      dispatch({
        type: SHOW_SEARCH_REQUEST_SUCCESS,
        query,
        results,
      });
    }).catch((error) => {
      console.log(error)
      dispatch({
        type: SHOW_SEARCH_REQUEST_ERROR,
        query
      });
    });
  };
}