import React from 'react'
import PropTypes from 'prop-types'
import WatchlistSeason from 'watchlist/components/WatchlistSeason'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  
});

class Watchlist extends React.Component {

  render() {
    const { aggregatedWatchlistData } = this.props;

    return (
      <>
        {
          aggregatedWatchlistData.map(({ showInfo, seasonNumber, episodes }) => {
            return (<WatchlistSeason key={showInfo.id + "-" + seasonNumber} episodes={episodes} showId={showInfo.id} showName={showInfo.name} />)
          })
        }
      </>
    );
  }
}

Watchlist.propTypes = {
  aggregatedWatchlistData: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Watchlist);