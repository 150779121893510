import React from 'react';
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({

});

class NavShowList extends React.Component {

  render() {
    const { showInfos } = this.props;
    
    if (!showInfos && showInfos.length === 0) {
      return null;
    }

    return (
      <div>
        <List>
          <ListItem button component={Link} to='/search'>
            <ListItemText primary='Search' />
          </ListItem>
          <ListItem button component={Link} to='/'>
              <ListItemText primary='Upcoming' />
          </ListItem>
          <ListItem button component={Link} to='/catch-up'>
              <ListItemText primary='Catch-up' />
          </ListItem>
        </List>
        <Divider />
        <List subheader={<ListSubheader disableSticky component='div'>Shows</ListSubheader>}>
                   {
            showInfos.map((showInfo) => {
                return (
                  <ListItem key={showInfo.id} button component={Link} to={`/shows/${showInfo.id}`}>
                    <ListItemText primary={showInfo.name} />
                  </ListItem>
                )
            })
          }
          
        </List>
      </div>
  )};
}

NavShowList.propTypes = {
  showInfos: PropTypes.array.isRequired,
}

export default withStyles(styles)(NavShowList)