import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'

import UpcomingDayContainer from '../containers/UpcomingDayContainer'

const styles = theme => ({
  
});

class Upcoming extends React.Component {
  generateDates() {
    let startDate = this.calculateStartDate();
    let result = [];
    result.push(startDate.format('YYYY-MM-DD'));
    for (var i = 0; i < 7; i++) {
      let nextDate = startDate.add(1, 'days');
      result.push(nextDate.format('YYYY-MM-DD'));

    };
    return result;
  }

  calculateStartDate() {
    return moment().subtract(3, 'hours').startOf('day');
  }

  render() {
    const dates = this.generateDates();
    return (
      <div>
        <Typography variant='h5' color='primary'>Upcoming</Typography>
        {
          dates.map((date) => {
            return (<UpcomingDayContainer key={date} date={date} />)
          })
        }
      </div>
    );
  }
}

Upcoming.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Upcoming);