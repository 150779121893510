import UserDataPersistence from 'api/UserDataPersistence'
import { getLastRetrievedVersion } from 'state/user/persistence/selectors'

import {
  PERSIST_USER_DATA_REQUEST,
  PERSIST_USER_DATA_SUCCESS,
  PERSIST_USER_DATA_ERROR,

  GET_USER_DATA_REQUEST,
  GET_USER_DATA_SUCCESS,
  GET_USER_DATA_ERROR

} from 'state/action-types'

export function requestPersistUserData({ idToken, data, lastRetrievedVersion }) {
  return (dispatch) => {
    dispatch({
      type: PERSIST_USER_DATA_REQUEST,
    });

    return UserDataPersistence.storeUserData(idToken, data, lastRetrievedVersion).then((result) => {
      dispatch({
        type: PERSIST_USER_DATA_SUCCESS,
        newVersion: result['version'],
      })
    }).catch((error) => {
      console.log(error);
      dispatch({
        type: PERSIST_USER_DATA_ERROR
      });
    });
  }
}

export function requestGetUserData({ idToken }) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_USER_DATA_REQUEST,
    });

    const lastRetrievedVersion = getLastRetrievedVersion(getState());

    return UserDataPersistence.getUserData(idToken, lastRetrievedVersion).then((result) => {
      if (result) {
        const data = JSON.parse(result.data);
        const watchlist = data.watchlist;
        const watched = data.watched;
        const lastRetrievedVersion = result.version;
        dispatch({
          type: GET_USER_DATA_SUCCESS,
          watchlist,
          watched,
          lastRetrievedVersion
        });
      }
    }).catch((error) => {
      console.log(error);
      dispatch({
        type: GET_USER_DATA_ERROR
      });
    });
  }
}