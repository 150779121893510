import React from 'react'
import PropTypes from 'prop-types'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import Typography from '@material-ui/core/Typography';
import UpcomingEpisode from './UpcomingEpisode'

import { prettyPrintDate } from 'utils'

import { withStyles } from '@material-ui/core/styles'
const styles = theme => ({
  container: {
    marginTop: 15,
  }
});

class UpcomingDay extends React.Component {
  render() {
    const { date, episodes, classes } = this.props;
    if (episodes.length === 0) {
      return null;
    }

    return (
      <div className={classes.container}>
        <Typography variant='h6'>{prettyPrintDate(date, true)}</Typography>
        <Table>
            <TableBody>
              {
                episodes.map((episode) => {
                  return (<UpcomingEpisode key={episode.id} episode={episode} />)
                })
              }
            </TableBody>
          </Table>

      </div>
    );
  }
}

UpcomingDay.propTypes = {
  classes: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  episodes: PropTypes.array.isRequired
}

export default withStyles(styles)(UpcomingDay);