import { combineReducers } from 'redux'
import { createReducer, getCurrentTimestamp } from 'utils'
import {
  SYNC_SHOW_SUCCESS,
} from 'state/action-types';

export const show = createReducer({}, {
  [ SYNC_SHOW_SUCCESS ] : (state, { showId }) => {
    return {
      ...state,
      [ showId ]: getCurrentTimestamp()
    }
  }
});


export default combineReducers({
  show
});
