import { combineReducers } from 'redux'
import { createReducer } from 'utils'
import {
    SHOW_INFO_REQUEST,
    SHOW_INFO_REQUEST_SUCCESS,
    SHOW_INFO_REQUEST_ERROR
} from 'state/action-types';

export const showId = createReducer(null, {
  [ SHOW_INFO_REQUEST ] : (state, { showId }) => showId
});

export const isFetchingShowInfo = createReducer(false, {
  [ SHOW_INFO_REQUEST ] : (state) => true,
  [ SHOW_INFO_REQUEST_SUCCESS ] : (state) => false,
  [ SHOW_INFO_REQUEST_ERROR ] : (state) => false,
});

export const showInfo = createReducer(null, {
  [ SHOW_INFO_REQUEST ] : (state) => null,
  [ SHOW_INFO_REQUEST_SUCCESS ] : (state, { showInfo }) => showInfo,
  [ SHOW_INFO_REQUEST_ERROR ] : (state) => null,
});

export const episodes = createReducer(null, {
  [ SHOW_INFO_REQUEST ] : (state) => null,
  [ SHOW_INFO_REQUEST_SUCCESS ] : (state, { episodes }) => episodes,
  [ SHOW_INFO_REQUEST_ERROR ] : (state) => null,
});

export default combineReducers({
  showId,
  isFetchingShowInfo,
  showInfo,
  episodes
});