import { combineReducers } from 'redux'
import auth from './auth/reducer'
import watchlist from './watchlist/reducer'
import persistence from './persistence/reducer'
import watched from './watched/reducer'
import subscription from './subscription/reducer'

export const reducer = combineReducers({
  auth,
  watchlist,
  subscription,
  persistence,
  watched,
})