import { combineReducers } from 'redux'
import { createReducer } from 'utils'
import {
    WATCHLIST_ADD_SEASON_REQUEST,
    WATCHLIST_ADD_SEASON_REQUEST_SUCCESS,
    WATCHLIST_ADD_SEASON_REQUEST_ERROR
 } from 'state/action-types';

export const addingToWatchlist = createReducer([], {
  [ WATCHLIST_ADD_SEASON_REQUEST ]: (state, { showId, seasonNumber} ) => [...state, showId + "-" + seasonNumber],
  [ WATCHLIST_ADD_SEASON_REQUEST_SUCCESS ]: (state, { showId, seasonNumber} ) => state.filter(item => item !== showId + "-" + seasonNumber),
  [ WATCHLIST_ADD_SEASON_REQUEST_ERROR ]: (state, { showId, seasonNumber} ) => state.filter(item => item !== showId + "-" + seasonNumber),
});

export default combineReducers({
  addingToWatchlist
});