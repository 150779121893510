import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  
});

class SeasonEpisodeCount extends React.Component {

  getEpisodesForm(count) {
    if (count > 1) {
      return 'episodes';
    } else {
      return 'episode';
    } 
  }

  generateText() {
    const { airedEpisodeCount, watchedEpisodeCount, totalEpisodeCount } = this.props;
    const unwatchedEpisodeCount = Math.max(0, airedEpisodeCount - watchedEpisodeCount);
    const allCaughtUp = unwatchedEpisodeCount === 0;

    let completeText = `${airedEpisodeCount} ${this.getEpisodesForm(airedEpisodeCount)}`;

    if (airedEpisodeCount !== totalEpisodeCount) {
      completeText += ` aired`
    }

    if (allCaughtUp) {
      completeText += ` / All caught up!`
    } else if (watchedEpisodeCount > 0) {
      completeText += ` / ${unwatchedEpisodeCount} unwatched`
    }

    return completeText;
  }
  
  render() {
      const { airedEpisodeCount } = this.props;
      if (airedEpisodeCount > 0) {
        return (
          <div><Typography variant='caption'>{this.generateText()}</Typography></div>
        );
      } else {
        return null;
      }
  }
}

SeasonEpisodeCount.propTypes = {
  airedEpisodeCount: PropTypes.number.isRequired,
  watchedEpisodeCount: PropTypes.number.isRequired,
  totalEpisodeCount: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SeasonEpisodeCount);