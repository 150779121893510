export function getSearchQuery( state ) {
  return state.ui.showSearch.query;
}

export function isSearching( state ) {
  return state.ui.showSearch.isSearching;
}

export function getSearchResults( state ) {
  return state.ui.showSearch.searchResults;
}