import React from 'react'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';

import WatchEpisodeButtonContainer from 'shows/containers/WatchEpisodeButtonContainer'

class EpisodeList extends React.Component {

  render() {
      const { episodes } = this.props;
      
      return (
        <Table padding='none'>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Date</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {episodes.map(episode => {
              return (
                <TableRow key={episode.id}>
                  <TableCell style={{width: '75%'}}>{episode.number}.&nbsp;&nbsp;{episode.name}</TableCell>
                  <TableCell style={{width: '15%'}}>{episode.airdate}</TableCell>
                  <TableCell style={{width: '10%'}}><WatchEpisodeButtonContainer episodeId={episode.id} /></TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      );
  }
}

EpisodeList.propTypes = {
  episodes: PropTypes.array.isRequired,
}

export default EpisodeList