import { getCurrentTimestamp } from 'utils'
import {
    MARK_EPISODE_WATCHED,
    UNMARK_EPISODE_WATCHED,
} from 'state/action-types';

export function markEpisodeWatched(episodeId) {
  return {
    type: MARK_EPISODE_WATCHED,
    episodeId,
    timestamp: getCurrentTimestamp(),
  }
}


export function unmarkEpisodeWatched(episodeId) {
  return {
    type: UNMARK_EPISODE_WATCHED,
    episodeId,
  }
}
