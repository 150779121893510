import fetch from 'isomorphic-fetch';

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const UserDataPersistence = {
  
  storeUserData(idToken, data, lastRetrievedVersion) {
    let body = JSON.stringify({ idToken, data });

    return fetch(`${endpoint}/api/userData/store`, {
        body: body,
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          'If-Match': lastRetrievedVersion
        })
    }).then(res => {
      if (res.ok) {
        const version = res.headers.get('Etag');
        return Promise.resolve({ version });
      } else if (res.status === 412) {
        return Promise.reject(new Error('Precondition Failed'));
      } else {
        return Promise.reject(new Error(`${res.status} ${res.statusText}`));
      }
    });

  },

  getUserData(idToken, lastRetrievedVersion) {
    return fetch(`${endpoint}/api/userData/get?idToken=${idToken}`, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          'If-None-Match': lastRetrievedVersion
        })
    }).then(res => {
      if (res.ok) {
        const version = res.headers.get('Etag');
        return res.json()
          .then((result) => Promise.resolve({ ...result, version }));

      } else if (res.status === 403) {
        return Promise.reject(new Error('Unauthorized'));
      } else {
        console.log('unknown error');
        return Promise.reject(new Error(`${res.status} ${res.statusText}`));
      }
    });
  }

}

export default UserDataPersistence