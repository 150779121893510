import { reducer as database } from 'state/database/reducer'
import { reducer as ui } from 'state/ui/reducer'
import { reducer as user } from 'state/user/reducer'

import storage from 'redux-persist/lib/storage'
import { persistCombineReducers } from 'redux-persist'

const config = {
  key: 'root',
  whitelist: ['database', 'user'],
  storage
}

const trakrApp = persistCombineReducers(config, {
  database,
  ui,
  user
})

export default trakrApp