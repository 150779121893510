import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const SearchResult = ({ show }) => (

  <Card className="search-result">
    <CardContent>
      <Typography variant="h5" component="h2">
        {show.name}
      </Typography>
      <Typography color="secondary" >
        {show.premiered ? "(" + show.premiered.slice(0, show.premiered.indexOf("-")) + ")" : null}
      </Typography>
      <Typography component="p" dangerouslySetInnerHTML={ {__html: show.summary} } />
    </CardContent>
    <CardActions>
      <Button size="small" color="primary" component={Link} to={'/shows/' + show.id}>
          Episodes
      </Button>
    </CardActions>
  </Card>
  
)

SearchResult.propTypes = {
  show: PropTypes.object.isRequired,
}

export default SearchResult