import TvMaze from 'api/TvMaze'
import moment from 'moment'
import union from 'lodash/union'
import { getCurrentTimestamp } from 'utils'
import { getWatchlistShows, getWatchlistShowSeasons } from 'state/user/watchlist/selectors'
import { getLastSyncTimeForShow } from 'state/database/sync/selectors'
import { getSubscriptionList, getShowSubscriptionTimestamp } from 'state/user/subscription/selectors'

import {
  SYNC_DATABSE_BEGIN,
  SYNC_DATABASE_COMPLETE,
  SYNC_SHOW_REQUEST,
  SYNC_SHOW_SUCCESS,
  SYNC_SHOW_ERROR,
  SUBSCRIPTION_ADD_NEW_WATCHLIST_SEASON,
} from 'state/action-types'

const delay = 1000;

function startShowSync(showId) {
  return {
    type: SYNC_SHOW_REQUEST,
    showId
  }
}

function syncShowComplete(showId, showInfo, episodes) {
  return {
    type: SYNC_SHOW_SUCCESS,
    showId,
    showInfo,
    episodes
  }
}

function syncShowError(showId, error) {
  return {
    type: SYNC_SHOW_ERROR,
    error
  }
}

function subscriptionAddedNewSeason(showId, seasonNumber) {
  return {
    type: SUBSCRIPTION_ADD_NEW_WATCHLIST_SEASON,
    showId,
    seasonNumber
  }
}

function getShowThunk(showId, dispatch, getState) {
  dispatch(startShowSync(showId));

  return Promise.all([
    TvMaze.getShow(showId),
    TvMaze.getEpisodes(showId)
  ]).then(values => {
      const showInfo = values[0];
      const episodesList = values[1];

      const newSeasons = findNewSeasonsFromEpisodes(
        episodesList,
        getWatchlistShowSeasons(getState(), showId),
        getShowSubscriptionTimestamp(getState(), showId));

      newSeasons.forEach(seasonNumber => dispatch(subscriptionAddedNewSeason(showId, seasonNumber)));

      const allSeasonsToInclude = union(getWatchlistShowSeasons(getState(), showId), newSeasons);
      const episodes = {};

      episodesList.forEach((episode) => {
        if (allSeasonsToInclude.indexOf(episode.season) < 0) return;

        if (!episodes.hasOwnProperty(episode.season)) {
          episodes[episode.season] = []
        }

        episodes[episode.season].push(episode)
      });

      return dispatch(syncShowComplete(showId, showInfo, episodes));
  }).catch((error) => {
    dispatch(syncShowError(showId))
  }); 
}

function createArtificialDelayPromise(delay) {
  return new Promise((resolve, reject) => setTimeout(resolve, delay) );
}

function createDelayedShowPromise(showId, delay, dispatch, getState) {
  return createArtificialDelayPromise(delay)
    .then((resolve, reject) => {
      return getShowThunk(showId, dispatch, getState);
    });
}

function startDatabaseSyncThunk() {
  return function(dispatch, getState) {
    const curTime = getCurrentTimestamp();
    const showsToSync = union(
        getWatchlistShows(getState()),
        Object.keys(getSubscriptionList(getState())).map(key => Number(key)))
      .filter(
        showId => (curTime - getLastSyncTimeForShow(getState(), showId)) > 24 * 3600
      );
    
    dispatch(startDatabaseSync(showsToSync));

    const allPromises = showsToSync.map((showId, index) => {
      return createDelayedShowPromise(
          showId,
          index * delay,
          dispatch,
          getState,
      );
    });

    return Promise.all(allPromises);
  }
}

function startDatabaseSync(showsToSync) {
  return {
      type: SYNC_DATABSE_BEGIN,
      showsToSync,
  }
}

function endDatabaseSync() {
  return {
    type: SYNC_DATABASE_COMPLETE,
  }
}

function findNewSeasonsFromEpisodes(episodes, watchlistSeasons, subscriptionTimestamp) {
  const newSeasons = {};

  episodes.forEach((episode) => {
    const episodeAirdateTimestamp = moment(episode.airdate).unix();
    if (episodeAirdateTimestamp >= subscriptionTimestamp && watchlistSeasons.indexOf(episode.season) < 0) {
      newSeasons[Number(episode.season)] = true;
    }
  })
  return Object.keys(newSeasons).map(key => Number(key)).sort();
}

export function requestSyncDatabase() {
  return (dispatch, getState) => {
    return dispatch(
      startDatabaseSyncThunk()
    ).then(() => {
      return dispatch(endDatabaseSync())
    });
  }
}