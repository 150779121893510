import { connect } from 'react-redux'
import Show from '../components/Show'
import { requestShowInfo as requestShowInfoAction } from 'state/ui/show-info/actions'
import { getCurrentShowInfo, getCurrentEpisodes, isFetchingShowInfo } from 'state/ui/show-info/selectors'

const mapStateToProps = (state, ownProps) => {
  return {
    id: ownProps.match.params.id,
    isFetchingShowInfo: isFetchingShowInfo(state),
    showInfo: getCurrentShowInfo(state),
    episodes: getCurrentEpisodes(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchShowInfo: (id) => {
      dispatch(requestShowInfoAction(id))
    },
  }
}

const ShowContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Show)

export default ShowContainer