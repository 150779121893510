import { createReducer } from 'utils'
import {
    WATCHLIST_ADD_SEASON_REQUEST_SUCCESS,
    SYNC_SHOW_SUCCESS,
    USER_SIGN_OUT,
} from 'state/action-types';

export default createReducer({}, {
  [ WATCHLIST_ADD_SEASON_REQUEST_SUCCESS ] : (state, { showId, seasonNumber, episodes }) => {
    const episodesMap = { 
      [showId] : {},
      ...state
    };
    episodesMap[showId][seasonNumber] = episodes;
    return episodesMap
  },
  [ SYNC_SHOW_SUCCESS ] : ( state, { showId, episodes }) => {
    return {
      ...state,
      [ showId ] : episodes
    };
  },

  [ USER_SIGN_OUT ] : () => { return {} },

});
