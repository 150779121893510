import { createReducer } from 'utils'
import {
    WATCHLIST_ADD_SEASON_REQUEST_SUCCESS,
    SYNC_SHOW_SUCCESS,
    USER_SIGN_OUT
} from 'state/action-types';

function updateShowInfoFromAction(state, { showId, showInfo }) {
  return {
    ...state,
    [ showId ]: showInfo,
  }
}

export default createReducer({}, {
  [ WATCHLIST_ADD_SEASON_REQUEST_SUCCESS ] : updateShowInfoFromAction,
  [ SYNC_SHOW_SUCCESS ] : updateShowInfoFromAction,
  [ USER_SIGN_OUT ] : () => { return {} },
});
