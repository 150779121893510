import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';

import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';

import moment from 'moment'

const quantities = [1,2,3,4,5,6,7,8,9,10];
const units = ['days', 'weeks', 'months', 'years'];

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 100,
  },
  quantityField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 50,
  },
  menu: {
    width: 200,
  },
  formLabel: {
    display: 'inline-flex',
    position: 'relative',
    marginTop: 0.5 * theme.spacing.unit,
    marginRight: theme.spacing.unit,
    fontSize: '1rem'
  }
});

class PastTimeRange extends React.Component {

  handleQuantityChange = (event) => {
    const newValue = moment.duration(event.target.value, this.getParsedDuration().unit);
    this.props.onDurationChange(newValue.toISOString());
  }

  handleUnitChange = (event) => {
    const newValue = moment.duration(this.getParsedDuration().quantity, event.target.value);
    this.props.onDurationChange(newValue.toISOString());
  }

  handleShowWatchedChange = (event) => {
    const newValue = event.target.checked;
    this.props.onShowWatchedChange(newValue);
  }

  getParsedDuration() {
    const durationMoment = moment.duration(this.props.duration);

    for (let i = 0; i < units.length; i++) {
      const unit = units[i];
      const quantity = durationMoment.as(unit);
      if (quantity > 0 && quantity <= quantities[quantities.length - 1] && quantity === Math.floor(quantity)) {
        return {
          quantity,
          unit
        }
      }
    }

    return {
      quantity: 2,
      unit: 'weeks'
    }
  }

  render() {
    const { showWatched, classes } = this.props;
    
    const duration = this.getParsedDuration();

    return (
      <Grid container spacing={24} justify="space-between">
        <Grid item >
        
          <Typography className={classes.formLabel} component='span'>
            See episodes from the past
          </Typography>
          <TextField select
              id='quantity'
              value={duration.quantity}
              onChange={this.handleQuantityChange}
              className={classes.quantityField}
              margin='none'
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }} >
            {quantities.map(quantity => (
              <MenuItem key={quantity} value={quantity}>
                {quantity}
              </MenuItem>
            ))}
          </TextField>

          <TextField select
              id='unit'
              value={duration.unit}
              onChange={this.handleUnitChange}
              className={classes.textField}
              margin='none'
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }} >
            {units.map(unit => (
              <MenuItem key={unit} value={unit}>
                {unit}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item >
          <Typography component='span' variant='overline' inline>
            Show watched episodes
          </Typography>
          <Switch checked={showWatched} value="showWatched" onChange={this.handleShowWatchedChange} />
        </Grid>
      </Grid>
    );
  }
}

PastTimeRange.propTypes = {
  classes: PropTypes.object.isRequired,
  duration: PropTypes.string.isRequired,
  onDurationChange: PropTypes.func.isRequired,
  showWatched: PropTypes.bool.isRequired,
  onShowWatchedChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(PastTimeRange);