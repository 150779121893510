import { combineReducers } from 'redux'
import { createReducer } from 'utils'
import {
    USER_SIGN_IN,
    USER_SIGN_OUT
} from 'state/action-types';


const defaultUser = {
      name: "",
      idToken: "",
      expiresAt: 0
    };

export const isSignedIn = createReducer(false, {
  [USER_SIGN_IN]: (state) => true,
  [USER_SIGN_OUT]: (state) => false
});

export const user = createReducer({ ...defaultUser } , {
  [USER_SIGN_IN]: (state, { user }) => user,
  [USER_SIGN_OUT]: (state) =>  { return {...defaultUser }}
})

export default combineReducers({
  isSignedIn,
  user
});