export const SHOW_SEARCH_REQUEST = 'SHOW_SEARCH_REQUEST';
export const SHOW_SEARCH_REQUEST_SUCCESS = 'SHOW_SEARCH_REQUEST_SUCCESS';
export const SHOW_SEARCH_REQUEST_ERROR = 'SHOW_SEARCH_REQUEST_ERROR';

export const SHOW_INFO_REQUEST = 'SHOW_INFO_REQUEST';
export const SHOW_INFO_REQUEST_SUCCESS = 'SHOW_INFO_REQUEST_SUCCESS';
export const SHOW_INFO_REQUEST_ERROR = 'SHOW_INFO_REQUEST_ERROR';

export const SUBSCRIPTION_ADD_SHOW = 'SUBSCRIPTION_ADD_SHOW';
export const SUBSCRIPTION_REMOVE_SHOW = 'SUBSCRIPTION_REMOVE_SHOW';
export const SUBSCRIPTION_ADD_NEW_WATCHLIST_SEASON = 'SUBSCRIPTION_ADD_NEW_WATCHLIST_SEASON';

export const WATCHLIST_ADD_SEASON_REQUEST = 'WATCHLIST_ADD_SEASON_REQUEST';
export const WATCHLIST_ADD_SEASON_REQUEST_SUCCESS = 'WATCHLIST_ADD_SEASON_REQUEST_SUCCESS';
export const WATCHLIST_ADD_SEASON_REQUEST_ERROR = 'WATCHLIST_ADD_SEASON_REQUEST_ERROR';

export const WATCHLIST_REMOVE_SEASON = "WATCHLIST_REMOVE_SEASON";

export const MARK_EPISODE_WATCHED = "MARK_EPISODE_WATCHED";
export const UNMARK_EPISODE_WATCHED = "UNMARK_EPISODE_WATCHED";

export const USER_SIGN_IN = "USER_SIGN_IN"
export const USER_SIGN_OUT = "USER_SIGN_OUT"

export const PERSIST_USER_DATA_REQUEST = "PERSIST_USER_DATA_REQUEST";
export const PERSIST_USER_DATA_SUCCESS = "PERSIST_USER_DATA_SUCCESS";
export const PERSIST_USER_DATA_ERROR = "PERSIST_USER_DATA_ERROR";

export const GET_USER_DATA_REQUEST = "GET_USER_DATA_REQUEST";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_ERROR = "GET_USER_DATA_ERROR";

export const SYNC_DATABSE_BEGIN = "SYNC_DATABSE_BEGIN"
export const SYNC_DATABASE_COMPLETE = "SYNC_DATABASE_COMPLETE";

export const SYNC_SHOW_REQUEST = "SYNC_SHOW_REQUEST";
export const SYNC_SHOW_SUCCESS = "SYNC_SHOW_SUCCESS";
export const SYNC_SHOW_ERROR = "SYNC_SHOW_ERROR";

export const BACKLOG_DURATION_CHANGE = "BACKLOG_DURATION_CHANGE";
export const BACKLOG_SHOW_WATCHED_CHANGE = "BACKLOG_SHOW_WATCHED_CHANGE";