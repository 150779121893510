import { connect } from 'react-redux'

import NavShowList from '../components/NavShowList'

import { getWatchlistShows } from 'state/user/watchlist/selectors'
import { getShowInfo } from 'state/database/selectors'

const mapStateToProps = (state) => {
  return {
    showInfos: getWatchlistShows(state).map(showId => getShowInfo(state, showId)).sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      })
  }
}

const NavShowListContainer = connect(
  mapStateToProps
)(NavShowList)

export default NavShowListContainer