import React from 'react';
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
});

class AuthButton extends React.Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handleSignOutAndClose = () => {
    this.props.onSignOut();
    this.handleClose();
  }

  handleSyncClick = () => {
    this.props.onSync();
    this.handleClose();
  }

  handleUserSyncClick = () => {
    this.props.onSyncUser({idToken: this.props.idToken});
    this.handleClose();
  }

  render() {
    const { isSignedIn, onSignIn, name } = this.props;
    const { anchorEl } = this.state;

    if (isSignedIn) {
      return (
        <div>
          <Button
            variant='contained'
            color='primary'
            aria-owns={anchorEl ? 'simple-menu' : null}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            {name}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
          >
            <MenuItem onClick={this.handleSignOutAndClose}>Logout</MenuItem>
            <MenuItem onClick={this.handleSyncClick}>Sync</MenuItem>
            <MenuItem onClick={this.handleUserSyncClick}>User</MenuItem>
          </Menu>

        </div>
      )

    } else {
      return (
        <div>
          <Button 
            variant='contained'
            color="primary" onClick={onSignIn}>
            Login
          </Button>
        </div>
      )
    }
  };
}

AuthButton.propTypes = {
  isSignedIn: PropTypes.bool.isRequired,
  onSignIn: PropTypes.func.isRequired,
  onSignOut: PropTypes.func.isRequired,
  onSync: PropTypes.func.isRequired,
  onSyncUser: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  idToken: PropTypes.string.isRequired,
}


export default withStyles(styles)(AuthButton);