import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import padStart from 'lodash/padStart'

import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';

import WatchEpisodeButtonContainer from 'shows/containers/WatchEpisodeButtonContainer'
import { prettyPrintTime } from 'utils'

const styles = theme => ({
  seasonEpisodeNumber: {
    display: "inline"
  },
  episodeName: {
    display: "inline",
  },
  row: {
    verticalAlign: 'text-top'
  }
});

class UpcomingEpisode extends React.Component {
  render() {
    const { episode, classes } = this.props;
    const showInfo = episode.showInfo;

    return (
      <TableRow className={classes.row}>
        <TableCell padding='dense' style={{width: '15%'}}><Typography>{prettyPrintTime(episode.airtime)}</Typography></TableCell>
        <TableCell padding='dense' style={{width: '75%'}}>
          <Typography color='primary' variant='subtitle1' component={Link} to={'/shows/' + showInfo.id}>{showInfo.name}</Typography>
        
          <Typography component="span" color='textSecondary' className={classes.seasonEpisodeNumber}>{episode.season}x{padStart(episode.number, 2, '0')} </Typography>
          <Typography component="span" className={classes.episodeName}>{episode.name}</Typography>
          <Typography component="p" paragraph={true} dangerouslySetInnerHTML={ {__html: episode.summary} } />
        </TableCell>
        <TableCell padding='dense' style={{width: '10%'}}><WatchEpisodeButtonContainer episodeId={episode.id} /></TableCell>
      </TableRow>
    );
  }
}

UpcomingEpisode.propTypes = {
  classes: PropTypes.object.isRequired,
  episode: PropTypes.object.isRequired
}

export default withStyles(styles)(UpcomingEpisode)