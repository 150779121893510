import React from 'react';

import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { compose, applyMiddleware, createStore } from 'redux'
import { persistStore } from 'redux-persist'
import thunkMiddleware from 'redux-thunk'
import persistenceTimerMiddleware from 'persistence/middleware'
import trakrApp from 'reducers'
import Header from '../components/Header'
import Search from 'search/containers/Search'
import ShowContainer from 'shows/containers/ShowContainer'
import BacklogContainer from 'backlog/containers/BacklogContainer'
import Upcoming from 'upcoming/components/Upcoming'

import { PersistGate } from 'redux-persist/lib/integration/react'

const logger = store => next => action => {
  let result = next(action)
  console.log(action.type, store.getState())
  return result
}

let store = createStore(
  trakrApp,
  undefined,
  compose(
    applyMiddleware(
        persistenceTimerMiddleware,
        thunkMiddleware,
        logger,
      ),
  )
)

let persistor = persistStore(store)

const styles = {
  container: {
    maxWidth: 900,
    margin: '8px auto',
    padding: '0 12px',
    flexGrow: 1,
  }
}

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    suppressDeprecationWarnings: true
  }
});

const App = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router>
        <MuiThemeProvider theme={theme}>
          <div>
            <CssBaseline />
            <Header />
            <div style={styles.container}>
              <Route exact path="/" component={Upcoming} />
              <Route path="/search" component={Search} />
              <Route path="/shows/:id" component={ShowContainer} />
              <Route path="/catch-up" component={BacklogContainer} />
            </div>
          </div>
        </MuiThemeProvider>
      </Router>
    </PersistGate>
  </Provider>
);

export default App;