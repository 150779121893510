import {
    SUBSCRIPTION_ADD_SHOW,
    SUBSCRIPTION_REMOVE_SHOW,
} from 'state/action-types';

export function subscribeToShow(showId, timestamp) {
  return {
    type: SUBSCRIPTION_ADD_SHOW,
    showId,
    timestamp,
  }
}

export function unsubscribeToShow(showId) {
  return {
    type: SUBSCRIPTION_REMOVE_SHOW,
    showId
  }
}