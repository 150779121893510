import { createReducer } from 'utils'
import {
    MARK_EPISODE_WATCHED,
    UNMARK_EPISODE_WATCHED,
    GET_USER_DATA_SUCCESS,
    USER_SIGN_OUT,
} from 'state/action-types';

export default createReducer({}, {
  [ MARK_EPISODE_WATCHED ] : (state, { episodeId, timestamp }) => {
      return {
        [episodeId] : timestamp,
        ...state
      };
  },

  [ UNMARK_EPISODE_WATCHED ] : (state, { episodeId }) => {
      const watched = { ...state };

      delete watched[episodeId];

      return watched;
  },


  [ GET_USER_DATA_SUCCESS ] : (state, { watched }) => {
    return watched;
  },

  [ USER_SIGN_OUT ] : (state) => { return {} },

});
