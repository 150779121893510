import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  showFact: {
      marginRight: 24,
  }
});

class ShowFact extends React.Component {
  render() {
    const { classes, title, content } = this.props;

    if (content) {
      return (
        <div className={classes.showFact}>
          <Typography color="secondary" variant="caption">
            {title}
          </Typography>
          <Typography gutterBottom>
            {content}
          </Typography>
        </div>
      )
    } else {
      return (null);
    }
  }
}


ShowFact.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ShowFact);