import { connect } from 'react-redux'

import Backlog from '../components/Backlog'

import { setBacklogDuration as setBacklogDurationAction } from 'state/ui/backlog/actions'
import { setBacklogShowWatched as setBacklogShowWatchedAction } from 'state/ui/backlog/actions'
import { getBacklogDuration } from 'state/ui/backlog/selectors'
import { getBacklogShowWatched } from 'state/ui/backlog/selectors'

const mapStateToProps = (state) => {
  return {
    duration: getBacklogDuration(state),
    showWatched: getBacklogShowWatched(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onBacklogDurationChange: (duration) => dispatch(setBacklogDurationAction(duration)),
    onBacklogShowWatchedChange: (showWatched) => dispatch(setBacklogShowWatchedAction(showWatched))
  }
}

const BacklogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Backlog)

export default BacklogContainer