import fetch from 'isomorphic-fetch';

function buildShowResult({id, name, status, runtime, premiered, schedule, network, webChannel, summary}) {
  return {
    id, //139
    name, // Girls
    status, // Ended
    runtime, // 30
    premiered, // 2012-04-15
    schedule, // { time: "22:00", days: ["Sunday"]}
    network : (network || webChannel), // { id: 8, name: "HBO", country: { name: "United States", code: "US", timezone: "America/New_York"}}
     // "webChannel":{"id":2,"name":"Hulu","country":{"name":"United States","code":"US","timezone":"America/New_York"}}
    summary // html string
  }
}

function buildEpisodeResult(showId, { id, name, season, number, airdate, airtime, summary }) {
  return {
    showId, // 139
    id, // 1935
    name, // Pilot
    season, // 1
    number, // 1
    airdate, // 2013-09-16
    airtime, // 21:00
    summary // html string
  }
}

function convertSearchResults(json) {
  return Promise.resolve(json.map(result => buildShowResult(result.show)));
}

const TvMaze = {
  
  /** Returns a promise that resolves to an array of search results */
  searchShow(query) {
    return fetch(`https://api.tvmaze.com/search/shows?q=${query}`)
      .then(response => response.json())
      .then(json => convertSearchResults(json));
  },

  /** Returns an object with show data */
  getShow(id) {
    return fetch(`https://api.tvmaze.com/shows/${id}`)
      .then(response => response.json())
      .then(showInfo => buildShowResult(showInfo));
  },

  getEpisodes(showId, seasonFilter) {

    return fetch(`https://api.tvmaze.com/shows/${showId}/episodes`)
      .then(response => response.json())
      .then((response) => {
        const shouldFilter = Array.isArray(seasonFilter) && seasonFilter.length > 0;
        return response.map(episode => buildEpisodeResult(showId, episode))
                      .filter(episode => !shouldFilter || seasonFilter.includes(episode.season));
      });
  },

  _getSeasons(id) {
    return fetch(`https://api.tvmaze.com/shows/${id}/seasons`)
      .then(response => response.json());
  },

  _getSeasonEpisodes(seasonId) {
    return fetch(`https://api.tvmaze.com/seasons/${seasonId}/episodes`)
      .then(response => response.json());
  },

}

export default TvMaze