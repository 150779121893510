import { createReducer } from 'utils'
import {
    SUBSCRIPTION_ADD_SHOW,
    SUBSCRIPTION_REMOVE_SHOW,
    USER_SIGN_OUT,
} from 'state/action-types';

export default createReducer({}, {
  [ SUBSCRIPTION_ADD_SHOW ]: (state, { showId, timestamp }) => {

    return {
      [ showId ] : timestamp,
      ...state
    }
  },

  [ SUBSCRIPTION_REMOVE_SHOW ]: (state, { showId }) => {
      const subscribed = { ...state };

      delete subscribed[showId];

      return subscribed;
  },

  [ USER_SIGN_OUT ] : (state) => { return {} },

});
