import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider';
import WatchEpisodeButtonContainer from 'shows/containers/WatchEpisodeButtonContainer'

const styles = theme => ({


});

class WatchlistSeason extends React.Component {
  render() {
    const { episodes, showName, showId } = this.props;
    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Typography variant="h6" color='primary' component={Link} to={'/shows/' + showId}>{showName}</Typography>
        </Grid>
        {episodes.map(episode => {
            return (
              <>
                <Grid item xs={12} container justify="space-between" alignItems="flex-end" spacing={0}>
                  <Grid item>
                    <Typography variant="overline">Season {episode.season}, Episode {episode.number} / {episode.airdate}</Typography>
                    <Typography variant="subtitle2" gutterBottom>{episode.name}</Typography>
                  </Grid>
                  <Grid item>
                    <WatchEpisodeButtonContainer episodeId={episode.id}  />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography component="p" dangerouslySetInnerHTML={ {__html: episode.summary} } />
                  </Grid>
                </Grid>

                <Grid item xs={12}><Divider/></Grid>
              </>
            )
        })}
        
      </Grid>
    );
  }
}

WatchlistSeason.propTypes = {
  classes: PropTypes.object.isRequired,
  episodes: PropTypes.array.isRequired,
  showId: PropTypes.number.isRequired,
  showName: PropTypes.string,
}

export default withStyles(styles)(WatchlistSeason);