import React from 'react'
import PropTypes from 'prop-types'
import SeasonContainer from '../containers/SeasonContainer'
import Typography from '@material-ui/core/Typography';
import ShowFact from './ShowFact'
import { withStyles } from '@material-ui/core/styles'
import SubscribeShowButtonContainer from '../containers/SubscribeShowButtonContainer';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  factBar: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: "0.35em",
    marginTop: "0.17em"
  },
  showInfo: {
    marginBottom: 16
  }
});


class Show extends React.Component {
  
  state = { expanded: false }

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const { showInfo, episodes, classes } = this.props;

    if (showInfo && episodes) {
      return (
        <Grid container spacing={16}>
          <Grid item xs={12} className={classes.showInfo}>
            <Typography variant="h5" gutterBottom>
              {showInfo.name}<SubscribeShowButtonContainer showId={showInfo.id} />
            </Typography>

            <div className={classes.factBar}>
              <ShowFact title='Network' content={showInfo.network.name} />

              <ShowFact title='Timeslot' content={showInfo.schedule.days[0]} />

              <ShowFact title='Premiered' content={showInfo.premiered} />

              <ShowFact title='Status' content={showInfo.status} />

              <ShowFact title='Runtime' content={showInfo.runtime ? showInfo.runtime + ' minutes' : null} />

            </div>

            <Typography component="p" dangerouslySetInnerHTML={ {__html: showInfo.summary} } gutterBottom />

          </Grid>
          {
            episodes.map((season, index) => {
              if (season) {
                return (<SeasonContainer key={index} episodes={episodes[index]} showId={showInfo.id} />)
              } else {
                return ""
              }
            })
          }
          
        </Grid>
      );
    } else {
      return (null);
    }
  }

  componentDidMount() {
    const { id, showInfo, fetchShowInfo } = this.props;
    if (!showInfo || id !== showInfo.id) {
      fetchShowInfo(id);
    }
  }

  componentDidUpdate(prevProps) {
    const previouslyFetching = prevProps.isFetchingShowInfo;
    const currentlyFetching = this.props.isFetchingShowInfo;

    const { id, showInfo, fetchShowInfo } = this.props;

    if (currentlyFetching) { 
      return;
    } else if (!previouslyFetching) {
      if (!showInfo || id !== showInfo.id) {
        console.log("fetch2");
        fetchShowInfo(id);
      }
    }
  }
}

Show.propTypes = {
  id: PropTypes.string,
  fetchShowInfo: PropTypes.func.isRequired,
  showInfo: PropTypes.object,
  episodes: PropTypes.array,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Show);