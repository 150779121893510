import React from 'react';
import PropTypes from 'prop-types'

class Gapi extends React.Component {

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/api.js";
    script.onload = () => {
      window.gapi.load('auth2', this.initClient);
    };

    document.body.appendChild(script);
  }

  initClient = () => {
    const gapi = window.gapi;

    gapi.auth2.init({
      'clientId': process.env.REACT_APP_GOOGLE_CLIENT_ID,
    }).then(this.gapiClientLoaded, function(reason) {
      console.log(reason)
    });
  }

  gapiClientLoaded = () => {
    const gapi = window.gapi;
    this.props.onInitialized(gapi);
  }

  render() {
    return null;
  }
}

Gapi.propTypes = {
  onInitialized: PropTypes.func.isRequired,
}

export default Gapi;