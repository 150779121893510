import {
  USER_SIGN_IN,
  USER_SIGN_OUT,
} from 'state/action-types'

export function signIn(user) {
  return {
    type: USER_SIGN_IN,
    user
  }
}

export function signOut() {
  return {
    type: USER_SIGN_OUT,
  }
}