import { combineReducers } from 'redux'
import { createReducer } from 'utils'
import {
    SHOW_SEARCH_REQUEST,
    SHOW_SEARCH_REQUEST_SUCCESS,
    SHOW_SEARCH_REQUEST_ERROR
} from 'state/action-types';

export const query = createReducer('', {
  [SHOW_SEARCH_REQUEST]: (state, { query }) => query
});

export const isSearching = createReducer(false, {
  [SHOW_SEARCH_REQUEST]: (state) => true,
  [SHOW_SEARCH_REQUEST_SUCCESS]: (state) => false,
  [SHOW_SEARCH_REQUEST_ERROR]: (state) => false
});

export const searchResults = createReducer(null, {
  [SHOW_SEARCH_REQUEST]: (state) => null,
  [SHOW_SEARCH_REQUEST_SUCCESS]: (state, { results }) => results,
  [SHOW_SEARCH_REQUEST_ERROR]: (state) => null
});

export default combineReducers({
  query,
  isSearching,
  searchResults
});