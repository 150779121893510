import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  wrapper: {
    margin: 8,
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  icon: {
    marginRight: 8
  }
  
});

class AddSeasonButton extends React.Component {

  handleButtonClick = () => {
    const { onAddSeason, showId, seasonNumber, isAdding, isOnWatchList, onRemoveSeason } = this.props;
    if (!isAdding) {
      if (isOnWatchList) {
        onRemoveSeason(showId, seasonNumber);        
      } else {
        onAddSeason(showId, seasonNumber);
      }
    } 
  }

  render() {
    const { isAdding, isOnWatchList, classes, isSignedIn } = this.props

    if ( !isSignedIn ) return null;

    return (
      <div className={classes.wrapper}>
        <Button
          variant='contained'
          size='small'
          color={isOnWatchList ? 'primary' : null }
          disabled={isAdding}
          onClick={this.handleButtonClick} >
          {
            (() => {
              if (isOnWatchList) {
                return (<PlaylistAddCheckIcon className={classes.icon}/>)  
              } else 
                return (<PlaylistAddIcon className={classes.icon}/>)
              }
            )()
          }

          Watchlist
          
        </Button>
        {isAdding && <CircularProgress size={24} className={classes.buttonProgress} /> }
      </div>
    )
  
  }
}


AddSeasonButton.propTypes = {
  onAddSeason: PropTypes.func,
  onRemoveSeason: PropTypes.func,
  isAdding: PropTypes.bool.isRequired,
  isOnWatchList: PropTypes.bool.isRequired,
  seasonNumber: PropTypes.number.isRequired,
  showId: PropTypes.number.isRequired,
  isSignedIn: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AddSeasonButton);