import React from 'react';
import { Link } from 'react-router-dom'

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';

import { withStyles } from '@material-ui/core/styles'
import AuthContainer from 'auth/containers/AuthContainer';
import SearchBoxContainer from 'search/containers/SearchBoxContainer'
import SyncProgressContainer from 'sync/containers/SyncProgressContainer'
import NavShowListContainer from 'chrome/containers/NavShowListContainer'

const drawerWidth = 350;

const styles = theme => ({
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    color: theme.palette.common.white
  },
  title: {
    flex: 1,
    textDecoration: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },

  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  drawerPaper: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
  }
});

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = { open: false }
  }

  handleOpenDrawer = () => this.setState({ open: !this.state.open })

  handleCloseDrawer = () => this.setState({ open: false })

  render() {
    const { classes } = this.props;
    
    return (
      <div>
        <AppBar position="static">
          <Toolbar>
            <IconButton className={classes.menuButton} aria-label="Menu" onClick={this.handleOpenDrawer}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" component={Link} to='/' className={classes.title} >
              tv-trakr
            </Typography>
            <Hidden xsDown>
              <SearchBoxContainer className={classes.search} />
            </Hidden>
            <AuthContainer />
          </Toolbar>
          <SyncProgressContainer />
        </AppBar>

        <Drawer className={classes.drawer} open={this.state.open} onClose={this.handleCloseDrawer}
          classes={{
                paper: classes.drawerPaper,
              }} >
          <div
            tabIndex={0}
            role="button"
            onClick={this.handleCloseDrawer}
            onKeyDown={this.handleCloseDrawer}
          >
            
            <div className={classes.drawerHeader}>
              <Typography variant="h6" color="primary" component={Link} to='/' className={classes.title}>
                tv-trakr
              </Typography>
              <IconButton onClick={this.handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <NavShowListContainer />
          </div>
        </Drawer>
      </div>
  )};
}

export default withStyles(styles)(Header)