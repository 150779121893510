import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { requestSearchResults as requestSearchResultsAction } from '../../state/ui/show-search/actions'
import SearchBox from '../components/SearchBox'

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { history } = ownProps;
  return {
    performSearch: (inputValue) => {
      dispatch(requestSearchResultsAction(inputValue));
      history.push('/search');
    },
  }
}

const SearchBoxContainer = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBox))

export default SearchBoxContainer