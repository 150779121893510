import {
    BACKLOG_DURATION_CHANGE,
    BACKLOG_SHOW_WATCHED_CHANGE
} from 'state/action-types';

export function setBacklogDuration(duration) {
  return {
    type: BACKLOG_DURATION_CHANGE,
    duration,
  }
}

export function setBacklogShowWatched(showWatched) {
  return {
    type: BACKLOG_SHOW_WATCHED_CHANGE,
    showWatched
  }
}