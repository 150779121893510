import { connect } from 'react-redux'
import SyncProgress from '../components/SyncProgress'
import { isSyncing, getSyncProgress } from 'state/ui/sync/selectors'

const mapStateToProps = (state) => {
  return {
    isSyncing: isSyncing(state),
    progress: getSyncProgress(state),
  }
}

const SyncProgressContainer = connect(
  mapStateToProps,
)(SyncProgress)

export default SyncProgressContainer
