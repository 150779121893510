import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
  root: {
    display: 'inline-block'
  }
  
});

class SubscribeShowButton extends React.Component {

  handleChange = () => {
    const isCurrentlySubscribed = this.props.isSubscribed;
    if (!isCurrentlySubscribed) {
      this.props.onSubscribeShow(this.props.showId)
    } else {
      this.props.onUnsubscribeShow(this.props.showId)
    }
  }

  render() {
    const { isSubscribed, isSignedIn, classes } = this.props
    
    if ( !isSignedIn ) return null;

    return (
      <div className={classes.root}>
        <IconButton aria-label="Subscribe" color="secondary" onClick={this.handleChange} disableRipple={true}>
          { isSubscribed && <StarIcon /> }
          { !isSubscribed && <StarBorderIcon /> }
        </IconButton>
        
      </div>
    )
  }
}


SubscribeShowButton.propTypes = {
  classes: PropTypes.object.isRequired,
  showId: PropTypes.number.isRequired,
  onSubscribeShow: PropTypes.func.isRequired,
  onUnsubscribeShow: PropTypes.func.isRequired,
  isSubscribed: PropTypes.bool.isRequired,
  isSignedIn: PropTypes.bool.isRequired,
}

export default withStyles(styles)(SubscribeShowButton);