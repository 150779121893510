import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import Grid from '@material-ui/core/Grid';

import PastTimeRange from './PastTimeRange'

import WatchlistContainer from 'watchlist/containers/WatchlistContainer'
const styles = theme => ({
  
});

class Backlog extends React.Component {

  onDurationChange = (duration) => {
    this.props.onBacklogDurationChange(duration);
  }

  onShowWatchedChange = (showWatched) => {
    this.props.onBacklogShowWatchedChange(showWatched);
  }

  render() {
    const { duration, showWatched } = this.props;

    let endDate = moment().format('YYYY-MM-DD');
    let startDate = moment().subtract(moment.duration(duration)).format('YYYY-MM-DD');

    return (
      <>
        <Grid item xs={12}>
          <Typography variant='h5' color='primary'>Catch-up</Typography>
        </Grid>
        <PastTimeRange duration={duration} showWatched={showWatched} onDurationChange={this.onDurationChange} onShowWatchedChange={this.onShowWatchedChange} />
        <WatchlistContainer startDate={startDate} endDate={endDate} showWatched={showWatched} />
      </>
    );
  }
}

Backlog.propTypes = {
  classes: PropTypes.object.isRequired,
  duration: PropTypes.string.isRequired,
  onBacklogDurationChange: PropTypes.func.isRequired,
  onBacklogShowWatchedChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(Backlog);