import get from 'lodash/get'
import forEach from 'lodash/forEach'
import sortBy from 'lodash/sortBy'

export const getShowInfo = (state, showId) => state.database.shows[showId];

export const getSeasonEpisodes = (state, showId, seasonNumber) => {
  return get(state.database.episodes, showId + '.' + seasonNumber, []);
}

export const getEpisodesByAirDate = (state, date, includeShowInfo) => {
  let result = [];

  forEach(state.database.episodes, (show) => {
    forEach(show, (season) => {
      forEach(season, (episode) => {
        if (episode.airdate === date) {
          result.push({
              ...episode,
              showInfo: getShowInfo(state, episode.showId)
            });
        }
      })
    })
  })

  return sortBy(result, ['airtime']);
}
