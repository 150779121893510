import { combineReducers } from 'redux'
import showSearch from './show-search/reducer'
import showInfo from './show-info/reducer'
import watchlist from './watchlist/reducer'
import sync from './sync/reducer'
import backlog from './backlog/reducer'

export const reducer = combineReducers({
  backlog,
  showSearch,
  showInfo,
  watchlist,
  sync,
});