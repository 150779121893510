import TvMaze from 'api/TvMaze'
import {
    WATCHLIST_ADD_SEASON_REQUEST,
    WATCHLIST_ADD_SEASON_REQUEST_SUCCESS,
    WATCHLIST_ADD_SEASON_REQUEST_ERROR,
    WATCHLIST_REMOVE_SEASON
} from 'state/action-types';

export function addSeasonToWatchListRequest(showId, seasonNumber) {
  return (dispatch) => {
    dispatch({
      type: WATCHLIST_ADD_SEASON_REQUEST,
      showId,
      seasonNumber
    });

    return Promise.all([
        TvMaze.getShow(showId),
        TvMaze.getEpisodes(showId)
      ]).then(values => {
          const showInfo = values[0];
          const episodes = values[1].filter(episode => episode.season === seasonNumber);

          return dispatch({
            type: WATCHLIST_ADD_SEASON_REQUEST_SUCCESS,
            showId,
            seasonNumber,
            showInfo,
            episodes
          });
      }).catch((error) => {
        dispatch({
          type: WATCHLIST_ADD_SEASON_REQUEST_ERROR,
          showId,
          seasonNumber,
        })
      }); 
  }
}

export function removeSeasonFromWatchList(showId, seasonNumber) {
  return {
    type: WATCHLIST_REMOVE_SEASON,
    showId,
    seasonNumber
  }
}
