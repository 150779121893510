import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';

const styles = theme => ({
  searchInput: {
    color: theme.palette.common.white,
  },

  searchIcon: {
    color: theme.palette.common.white,
    verticalAlign: 'middle'
  },

  wrapper: {
    color: theme.palette.common.white,
    background: fade(theme.palette.common.white, 0.15),
    padding: '2px 8px',
    borderRadius: 2,
    '&:hover': {
      background: fade(theme.palette.common.white, 0.25),
    },
    marginRight: 24
  }
});

class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.textInput = null; 
  }

  onSearchSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.performSearch(this.textInput.value);
  }

  setInputRef = (inputRef) => {
    this.textInput = inputRef;
  }

  render() {
    const { classes, value } = this.props;
    return(
      <form onSubmit={this.onSearchSubmit}>
        <div className={classes.wrapper} >
          <Input
              placeholder="Search"
              //className={classes.input}
              inputProps={{
                'aria-label': 'Search',
                'name': 'query',
                
              }}
              value={value}
              inputRef={this.setInputRef}
              className={classes.searchInput}
              disableUnderline={true}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              }
            />
          </div>
      </form>
    );
  }
}

SearchBox.propTypes = {
  value: PropTypes.string,
  performSearch: PropTypes.func.isRequired,
}

export default withStyles(styles)(SearchBox);