import { combineReducers } from 'redux'
import { createReducer } from 'utils'
import {
    SYNC_DATABSE_BEGIN,
    SYNC_DATABASE_COMPLETE,
    SYNC_SHOW_SUCCESS,
    SYNC_SHOW_ERROR,
} from 'state/action-types';

export const isSyncing = createReducer(false, {
  [ SYNC_DATABSE_BEGIN ]: (state) => true,
  [ SYNC_DATABASE_COMPLETE ]: (state) => false,
});

export const showsCompleted = createReducer(0, {
  [SYNC_DATABSE_BEGIN]: (state) => 0,
  [SYNC_SHOW_SUCCESS]: (state) => state + 1,
  [SYNC_SHOW_ERROR]: (state) => state + 1
});

export const showsTotal = createReducer(1, {
  [SYNC_DATABSE_BEGIN]: (state, { showsToSync }) => showsToSync.length,
});

export default combineReducers({
  isSyncing,
  showsCompleted,
  showsTotal
});