import { connect } from 'react-redux'

import UpcomingDay from '../components/UpcomingDay'

import { getEpisodesByAirDate } from 'state/database/selectors'
import { isShowSeasonOnWatchList } from 'state/user/watchlist/selectors'

const mapStateToProps = (state, ownProps) => {

  return {
    date: ownProps.date,
    episodes: getEpisodesByAirDate(state, ownProps.date, true)
      .filter((episode) => isShowSeasonOnWatchList(state, episode.showInfo.id, episode.season))
  }
}


const UpcomingDayContainer = connect(
  mapStateToProps
)(UpcomingDay)

export default UpcomingDayContainer