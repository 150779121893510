import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';

import SeasonHeader from './SeasonHeader'
import EpisodeList from './EpisodeList'

const styles = theme => ({

});

class Season extends React.Component {
  render() {
      const { episodes, showId, showName, airedEpisodeCount, watchedEpisodeCount, isOnWatchList } = this.props;
      return (
        <Grid item xs={12}>
          <ExpansionPanel defaultExpanded={isOnWatchList && watchedEpisodeCount !== episodes.length} >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <SeasonHeader 
              showId={showId}
              showName={showName}
              episodes={episodes}
              airedEpisodeCount={airedEpisodeCount}
              watchedEpisodeCount={watchedEpisodeCount} />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <EpisodeList episodes={episodes} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      );
  }
}

Season.propTypes = {
  episodes: PropTypes.array.isRequired,
  showId: PropTypes.number.isRequired,
  airedEpisodeCount: PropTypes.number.isRequired,
  watchedEpisodeCount: PropTypes.number.isRequired,
  isOnWatchList: PropTypes.bool.isRequired,
  showName: PropTypes.string,
}

export default withStyles(styles)(Season)