import { connect } from 'react-redux'
import AddSeasonButton from '../components/AddSeasonButton'

import { getIsSignedIn } from 'state/user/auth/selectors'

import { addSeasonToWatchListRequest as addSeasonToWatchListRequestAction } from 'state/user/watchlist/actions'
import { removeSeasonFromWatchList as removeSeasonFromWatchListAction } from 'state/user/watchlist/actions'

import { isShowSeasonOnWatchList } from 'state/user/watchlist/selectors'
import { isAddingShowSeasonToWatchList } from 'state/ui/watchlist/selectors'

const mapStateToProps = (state, ownProps) => {
  const { showId, seasonNumber } = ownProps;
  
  const isAdding = isAddingShowSeasonToWatchList(state, showId, seasonNumber);
  const isOnWatchList = isShowSeasonOnWatchList(state, showId, seasonNumber);

  return {
    ...ownProps,
    isSignedIn: getIsSignedIn(state),
    isAdding,
    isOnWatchList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddSeason: (showId, seasonNumber) => dispatch(addSeasonToWatchListRequestAction(showId, seasonNumber)),
    onRemoveSeason: (showId, seasonNumber) => dispatch(removeSeasonFromWatchListAction(showId, seasonNumber)),
  }
}

const AddSeasonButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSeasonButton)

export default AddSeasonButtonContainer
