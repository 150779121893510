import React from 'react';
import PropTypes from 'prop-types'

import Gapi from './Gapi';
import AuthButton from './AuthButton'

class Auth extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      gapi: null,
      isSignedIn: false
    }
  }

  onGapiInitialized = (gapi) => {

    this.setState((prevState) => {
      return { gapi };
    });

    gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSignInStatus);
    this.updateSignInStatus(gapi.auth2.getAuthInstance().isSignedIn.get())
  }

  updateSignInStatus = (isSignedIn) => {
    if (isSignedIn) {
      const GoogleAuth = this.state.gapi.auth2.getAuthInstance();
      const GoogleUser =  GoogleAuth.currentUser.get();

      const user = {
        name: GoogleUser.getBasicProfile().getGivenName(),
        idToken: GoogleUser.getAuthResponse().id_token,
        expiresAt: GoogleUser.getAuthResponse().expires_at,
      }
      
      this.props.signIn(user);
    } else {
      this.props.signOut();
    }
  }

  handleSignInClick = () => {
    if (this.state.gapi) {
      this.state.gapi.auth2.getAuthInstance().signIn();
    }
  }

  handleSignOutClick = () => {
    if (this.state.gapi) {
      this.state.gapi.auth2.getAuthInstance().disconnect();
    }
  }

  render() {
    const { isSignedIn, name, sync, syncUser, idToken } = this.props;

    return (
      <div>
        <Gapi onInitialized={this.onGapiInitialized}/>
        <AuthButton
            isSignedIn={isSignedIn}
            name={name}
            onSignIn={this.handleSignInClick}
            onSignOut={this.handleSignOutClick}
            onSync={sync}
            onSyncUser={syncUser}
            idToken={idToken} />
      </div>
  )};
}

Auth.propTypes = {
  signIn: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  sync: PropTypes.func.isRequired,
  isSignedIn: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  
}

export default Auth;