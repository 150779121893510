import { combineReducers } from 'redux'
import { createReducer } from 'utils'
import {
    GET_USER_DATA_SUCCESS,
    PERSIST_USER_DATA_SUCCESS,
    USER_SIGN_OUT,

} from 'state/action-types';

export const lastRetrievedVersion = createReducer(0, {
  [ GET_USER_DATA_SUCCESS ] : (state, { lastRetrievedVersion }) => lastRetrievedVersion,
  [ PERSIST_USER_DATA_SUCCESS ]: (state, { newVersion }) => newVersion,
  [ USER_SIGN_OUT ] : (state) => 0,
});

export default combineReducers({
  lastRetrievedVersion
});