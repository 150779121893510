import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import SeasonEpisodeCount from './SeasonEpisodeCount'
import AddSeasonButtonContainer from '../containers/AddSeasonButtonContainer'

const styles = theme => ({
  header: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
  },
  subscribeSeason: {
    marginLeft: 'auto',
  }

});

class SeasonHeader extends React.Component {
  
  buildShowLink(showName, showId, seasonNumber) {
    return (
      <div>
        <Typography variant='h6' color='primary' component={Link} to={'/shows/' + showId}>{showName} - Season {seasonNumber}</Typography>
      </div>
    );
  }

  buildSeasonTitle(seasonNumber) {
    return (
      <div>
        <Typography variant='h6' color='primary'>
          Season {seasonNumber}
        </Typography>
      </div>
    );
  }

  render() {
      const { episodes, showId, showName, airedEpisodeCount, watchedEpisodeCount, classes } = this.props;
      const seasonNumber = episodes[0].season;

      return (
        
        <div className={classes.header}>
          <div>
            { showName ? this.buildShowLink(showName, showId, seasonNumber) : this.buildSeasonTitle(seasonNumber)}
            <SeasonEpisodeCount 
              airedEpisodeCount={airedEpisodeCount}
              watchedEpisodeCount={watchedEpisodeCount}
              totalEpisodeCount={episodes.length} />
          </div>
          <div className={classes.subscribeSeason}>
            <AddSeasonButtonContainer showId={showId} seasonNumber={seasonNumber}/>
          </div>
        </div>

      );
  }
}

SeasonHeader.propTypes = {
  episodes: PropTypes.array.isRequired,
  showId: PropTypes.number.isRequired,
  showName: PropTypes.string,
  airedEpisodeCount: PropTypes.number.isRequired,
  watchedEpisodeCount: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SeasonHeader);