import { createReducer } from 'utils'
import {
    WATCHLIST_ADD_SEASON_REQUEST,
    WATCHLIST_REMOVE_SEASON,
    SUBSCRIPTION_ADD_NEW_WATCHLIST_SEASON,

    GET_USER_DATA_SUCCESS,
    USER_SIGN_OUT,
 } from 'state/action-types';

function addShowSeasonToWatchlistState(state, { showId, seasonNumber }) {
      const watchlist = {
        [showId]: [],
        ...state
      }
      if (watchlist[showId].indexOf(seasonNumber) === -1) {
        watchlist[showId].push(seasonNumber);
        watchlist[showId].sort();
      }
      return watchlist;
}

export default createReducer({}, {
  [ WATCHLIST_ADD_SEASON_REQUEST ] : addShowSeasonToWatchlistState,
  [ SUBSCRIPTION_ADD_NEW_WATCHLIST_SEASON ] : addShowSeasonToWatchlistState,

  [ WATCHLIST_REMOVE_SEASON ] : (state, { showId, seasonNumber }) => {
      const watchlist = { ...state };

      if (watchlist.hasOwnProperty(showId)) {
        const newSeasonList = watchlist[showId].filter(subscribedSeason => subscribedSeason !== seasonNumber);

        if (newSeasonList.length === 0) {
          delete watchlist[showId];
        } else  {
          watchlist[showId] = newSeasonList;
        }
      }

      return watchlist;
  },


  [ GET_USER_DATA_SUCCESS ] : (state, { watchlist }) => {
    return watchlist;
  },

  [ USER_SIGN_OUT ] : (state) => { return {} },

});
